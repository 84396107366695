import { useState } from 'react';

const _isArray = (value) => {
  return Array.isArray(value);
};

const findBrTag = (string) => {
  return (string.match(/<br>/g) || []).length;
};

const generateStringWithoutTags = (string) => {
  const countOfTags = findBrTag(string);
  const firstIndex = string.indexOf('<');
  const lastIndex = string.lastIndexOf('>');
  const firstSubstring = string.substring(0, firstIndex);
  const lastSubstring = string.substring(lastIndex + 1);
  let lineBreaks = '';
  for (let index = 0; index < countOfTags; index++) {
    lineBreaks += '\n';
  }
  const fullNewString = firstSubstring + lineBreaks + lastSubstring;
  return fullNewString;
};

const generateStringWithoutTagsSpan = (string) => {
  const htmlString = string;
  const div = document.createElement('div'); // Создаем временный элемент для парсинга
  div.innerHTML = htmlString;
  const spans = div.querySelectorAll('span');
  const content = Array.from(spans)
    .map((span) => span.textContent)
    .join('');

  console.log('content', content);

  return content;
};

const useForm = (initialState) => {
  const [fields, setValues] = useState(initialState);

  const _onChangeEditor = (event) => {
    // console.log('event editor', event.inputType);
  };

  const _onKeyDown = (event) => {
    if (event.key === 'Enter') {
      document.execCommand('insertLineBreak');
      event.preventDefault();
    }

    if (event.keyCode === 9) {
      //add tab
      document.execCommand('insertHTML', false, '&#009');
      //prevent focusing on next element
      event.preventDefault();
    }
  };

  /* Change values handler */
  const _onChange = (event) => {
    const value = event.target.value;

    if (value || value === '') {
      setValues({
        ...fields,
        [event.target.name]: value,
      });
      return;
    }

    const divValue = event?.currentTarget?.innerHTML;
    const divName = event?.currentTarget?.id;

    const fullValue = generateStringWithoutTagsSpan(divValue);
    const withouBR = generateStringWithoutTags(divValue);

    if (divName) {
      const currentValue = fields[divName];
      if (divValue === currentValue) return;

      if (fullValue) {
        setValues({
          ...fields,
          [divName]: fullValue,
        });
        return;
      }

      if (divValue) {
        setValues({
          ...fields,
          [divName]: withouBR,
        });
        return;
      }
    }
  };

  /* Change values handler for multiselect */
  const _onChangeMultiselect = (event) => {
    const value = event.target.value;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setValues({ ...fields, [event.target.name]: newValue });
  };

  const _onCheckboxChange = (event) => {
    setValues({ ...fields, [event.target.name]: event.target.checked });
  };

  /* Setter to force a value change  */
  const _set = (key, value) => {
    const list = { ...fields };
    list[key] = value;
    setValues(list);
  };

  /* Force values initialization */
  const _init = (values) => {
    setValues({ ...values });
  };

  /* Resetting values state  */
  const _reset = () => {
    const emptyState = {};

    for (const key in fields) {
      if (_isArray(fields[key])) emptyState[key] = [];
      else emptyState[key] = null;
    }

    setValues(emptyState);
  };

  return {
    fields,
    onChangeForm: _onChange,
    onChangeMultiselect: _onChangeMultiselect,
    onResetForm: _reset,
    onInitForm: _init,
    onCheckbox: _onCheckboxChange,
    setValue: _set,
    onChangeEditor: _onChangeEditor,
    onKeyDown: _onKeyDown,
  };
};

export default useForm;
