/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import TextArea from 'components/Elements/TextArea/TextArea';
import Flexbox from 'components/Flexbox/Flexbox';
import Popup from 'components/Popup/Popup';

import useForm from 'hooks/useForm';
import { Paragraph } from 'components/Typography';
import { updateShortlist } from '../api/shortlist';
import useDashboardDispatcher from 'features/dashboard/redux/useDashboardDispatcher';
import { useState } from 'react';
import TextEditor from 'components/Elements/TextEditor/TextEditor';
import formatAndReturnComponent, {
  baseFormatValue,
} from 'components/Elements/TextEditor/formatting';
import toast from 'react-hot-toast';

const EditShortlistModal = ({ open, onClose, data }) => {
  const { setDashboardUpdating } = useDashboardDispatcher();
  const [isLoading, setIsLoading] = useState(false);

  const { fields, onChangeForm, onResetForm, onInitForm, onKeyDown } = useForm({
    id: data?.id || null,
    mainTitle: data?.mainTitle || null,
    title: data?.title || null,
    article: data?.article || null,
    shortArticle: data?.shortArticle || null,
    description: data?.description || null,
    tag: data?.tag || null,
    summary: data?.summary || null,
    audioText: data?.author || null,
    author: data?.author || null,
    arabicMainTitle: data?.arabicMainTitle || null,
    arabicTitle: data?.arabicTitle || null,
    arabicArticle: data?.arabicArticle || null,
    arabicShortArticle: data?.arabicShortArticle || null,
    arabicDescription: data?.arabicDescription || null,
    arabicTag: data?.arabicTag || null,
    arabicSummary: data?.arabicSummary || null,
    arabicAudioText: data?.arabicAudioText || null,
    arabicAuthor: data?.arabicAuthor || null,
    url: data?.url || null,
  });

  const [selectedText, setSelectedText] = useState({});
  const [currentLargingComponent, setCurrentLargingComponent] = useState({});

  const handleCloseModal = () => {
    onClose();
    onResetForm();
    setCurrentLargingComponent({});
    setLocaleTextFormat({});
  };

  /* Effect for update field states for column data */
  useEffect(() => {
    if (data && open)
      onInitForm({
        id: data?.id || null,
        mainTitle: data?.mainTitle || null,
        title: data?.title || null,
        article: data?.article || null,
        shortArticle: data?.shortArticle || null,
        description: data?.description || null,
        tag: data?.tag || null,
        summary: data?.summary || null,
        audioText: data?.author || null,
        author: data?.author || null,
        arabicMainTitle: data?.arabicMainTitle || null,
        arabicTitle: data?.arabicTitle || null,
        arabicArticle: data?.arabicArticle || null,
        arabicShortArticle: data?.arabicShortArticle || null,
        arabicDescription: data?.arabicDescription || null,
        arabicTag: data?.arabicTag || null,
        arabicSummary: data?.arabicSummary || null,
        arabicAudioText: data?.arabicAudioText || null,
        arabicAuthor: data?.arabicAuthor || null,
        url: data?.url || null,
      });
  }, [data, open]);

  // ------------------------------------------
  const [localeTextFormat, setLocaleTextFormat] = useState({});

  const handleUpdate = () => {
    setIsLoading(true);
    updateShortlist({
      ...fields,
      ...localeTextFormat,
    }).then((response) => {
      if (response?.message) toast.error(response?.message);

      onClose();
      onResetForm();
      setDashboardUpdating(true);
      setSelectedText({});
      setCurrentLargingComponent({});
      setIsLoading(false);
    });
  };

  const handleFormatText = (value, formatName, fontSize) => () => {
    const selected = window.getSelection().toString();
    const formatRules = localeTextFormat[formatName]
      ? localeTextFormat[formatName]
      : data[formatName];

    const obj = formatAndReturnComponent(
      value,
      formatRules,
      selected,
      fontSize,
    );

    const { component, newFormat } = obj;
    // console.log(newFormat);
    setLocaleTextFormat({ ...localeTextFormat, [formatName]: newFormat });
    setCurrentLargingComponent({
      ...currentLargingComponent,
      [formatName]: component,
    });
  };

  return (
    <Popup
      open={open}
      onClose={!isLoading ? handleCloseModal : () => {}}
      header
      title="Edit Information"
      submitLabel="Update"
      canceLabel="Cancel"
      onSubmit={handleUpdate}
      overrideStyles={{ maxWidth: '1000px' }}
      loading={isLoading}
    >
      <Flexbox gap="20px">
        <Flexbox flexDirection="column" width="100%" gap="20px">
          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Section"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="mainTitle"
                onKeyDown={onKeyDown}
                value={fields.mainTitle}
                onChange={onChangeForm}
                formatName="mainTitleFormat"
                formatedValue={
                  currentLargingComponent?.mainTitleFormat ||
                  baseFormatValue(fields.mainTitle, data?.mainTitleFormat)
                }
                format={data?.mainTitleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Section"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="arabicMainTitle"
                value={fields.arabicMainTitle}
                onChange={onChangeForm}
                onKeyDown={onKeyDown}
                formatName="arabicMainTitleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicMainTitleFormat ||
                  baseFormatValue(
                    fields.arabicMainTitle,
                    data?.arabicMainTitleFormat,
                  )
                }
                format={data?.arabicMainTitleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Title"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="title"
                value={fields.title}
                onKeyDown={onKeyDown}
                onChange={onChangeForm}
                formatName="titleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.titleFormat ||
                  baseFormatValue(fields.title, data?.titleFormat)
                }
                format={data?.titleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Title"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="arabicTitle"
                value={fields.arabicTitle}
                onChange={onChangeForm}
                onKeyDown={onKeyDown}
                formatName="arabicTitleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicTitleFormat ||
                  baseFormatValue(fields.arabicTitle, data?.arabicTitleFormat)
                }
                format={data?.arabicTitleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="author"
                value={fields.author}
                onChange={onChangeForm}
                formatName="authorFormat"
                onKeyDown={onKeyDown}
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.authorFormat ||
                  baseFormatValue(fields.author, data?.authorFormat)
                }
                format={data?.authorFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="arabicAuthor"
                value={fields.arabicAuthor}
                onChange={onChangeForm}
                onKeyDown={onKeyDown}
                formatName="arabicAuthorFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicAuthorFormat ||
                  baseFormatValue(fields.arabicAuthor, data?.arabicAuthorFormat)
                }
                format={data?.arabicAuthorFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author description"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                name="description"
                onKeyDown={onKeyDown}
                value={fields.description}
                onChange={onChangeForm}
                formatName="descriptionFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.descriptionFormat ||
                  baseFormatValue(fields.description, data?.descriptionFormat)
                }
                format={data?.descriptionFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author description"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                onKeyDown={onKeyDown}
                name="arabicDescription"
                value={fields.arabicDescription}
                onChange={onChangeForm}
                formatName="arabicDescriptionFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicDescriptionFormat ||
                  baseFormatValue(
                    fields.arabicDescription,
                    data?.arabicDescriptionFormat,
                  )
                }
                format={data?.arabicDescriptionFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Summary"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                onKeyDown={onKeyDown}
                name="summary"
                value={fields.summary}
                onChange={onChangeForm}
                formatName="summaryFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.summaryFormat ||
                  baseFormatValue(fields.summary, data?.summaryFormat)
                }
                format={data?.summaryFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Summary"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                onKeyDown={onKeyDown}
                name="arabicSummary"
                value={fields.arabicSummary}
                onChange={onChangeForm}
                formatName="arabicSummaryFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicSummaryFormat ||
                  baseFormatValue(
                    fields.arabicSummary,
                    data?.arabicSummaryFormat,
                  )
                }
                format={data?.arabicSummaryFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Short Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextEditor
                onKeyDown={onKeyDown}
                name="shortArticle"
                value={fields.shortArticle}
                onChange={onChangeForm}
                formatName="shortArticleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.shortArticleFormat ||
                  baseFormatValue(fields.shortArticle, data?.shortArticleFormat)
                }
                format={data?.shortArticleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Short Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                onKeyDown={onKeyDown}
                name="arabicShortArticle"
                value={fields.arabicShortArticle}
                onChange={onChangeForm}
                formatName="arabicShortArticleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicShortArticleFormat ||
                  baseFormatValue(
                    fields.arabicShortArticle,
                    data?.arabicShortArticleFormat,
                  )
                }
                format={data?.arabicShortArticleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextEditor
                onKeyDown={onKeyDown}
                name="article"
                value={fields.article}
                onChange={onChangeForm}
                formatName="articleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.articleFormat ||
                  baseFormatValue(fields.article, data?.articleFormat)
                }
                format={data?.articleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextEditor
                onKeyDown={onKeyDown}
                name="arabicArticle"
                value={fields.arabicArticle}
                onChange={onChangeForm}
                formatName="arabicArticleFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicArticleFormat ||
                  baseFormatValue(
                    fields.arabicArticle,
                    data?.arabicArticleFormat,
                  )
                }
                format={data?.arabicArticleFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Audio Text"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextEditor
                onKeyDown={onKeyDown}
                name="audioText"
                value={fields.audioText}
                onChange={onChangeForm}
                formatName="audioTextFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.audioTextFormat ||
                  baseFormatValue(fields.audioText, data?.audioTextFormat)
                }
                format={data?.audioTextFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Audio Text"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />

              <TextEditor
                onKeyDown={onKeyDown}
                name="arabicAudioText"
                value={fields.arabicAudioText}
                onChange={onChangeForm}
                formatName="arabicAudioTextFormat"
                unicodeBidi
                formatedValue={
                  currentLargingComponent?.arabicAudioTextFormat ||
                  baseFormatValue(
                    fields.arabicAudioText,
                    data?.arabicAudioTextFormat,
                  )
                }
                format={data?.arabicAudioTextFormat || {}}
                selectedText={selectedText}
                onFormatText={handleFormatText}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Tag"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                onKeyDown={onKeyDown}
                placeholder="Tag"
                name="tag"
                value={fields.tag}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>

            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Tag"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                onKeyDown={onKeyDown}
                placeholder="Tag"
                name="arabicTag"
                value={fields.arabicTag}
                onChange={onChangeForm}
                unicodeBidi
                overrideStyles={{
                  textAlign: 'right',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Url"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Url"
                name="url"
                value={fields.url}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Popup>
  );
};

export default EditShortlistModal;
