import axios from 'axios';

const API_URL = 'https://gerard.dataox.io/api';

const defaultInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

defaultInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return error.response.data;
  },
);

defaultInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return error.response.data;
  },
);

const request = ({
  url,
  method = 'GET',
  data,
  params,
  headers,
  responseType,
  body = true,
}) => {
  return defaultInstance({
    url,
    method,
    data,
    params,
    headers,
    responseType,
  }).then((response) => {
    const bodyResponse = body ? response.data : response;
    return bodyResponse;
  });
};

export default request;
