/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import { H2, Paragraph } from 'components/Typography';

import OneDatePicker from 'components/Elements/DatePicker/OneDatePicker';
import { Button, ClearButton, SearchField } from 'components/Elements';
import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import Flexbox from 'components/Flexbox/Flexbox';
import { DragDropTable } from 'components/Table';
import Paper from 'components/Paper/Paper';

import columnArticleShape from '../core/columnShape';
import palette from 'theme/palette';
import {
  autoSortShortlist,
  getReportAll,
  getShortlistAll,
  removeAllShortlist,
  removeOneShortlist,
  reorderShortlist,
} from '../api/shortlist';
import useDashboardDispatcher from 'features/dashboard/redux/useDashboardDispatcher';
import ExportShortlistModal from './ExportShortlistModal';

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

const ShortlistDatatable = () => {
  const { updateDashboard } = useDashboardDispatcher();
  const [articles, setArticles] = useState(null);
  const [updateFetch, setUpdateFetch] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [lastHoursActive, setLastHoursActive] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [currentLang, setCurrentLang] = useState('');
  const [reports, setReports] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [articleFullData, setArticleFullData] = useState([]);

  const [dates, setDates] = useState({
    dateFrom: '',
    dateTo: '',
  });

  const hasFilter =
    globalFilter || dates.dateFrom || dates.dateTo || lastHoursActive;

  const getArticleFetcher = (page = 0, init = false) => {
    let dateFromUTC = dates.dateFrom
      ? dayjs(dates.dateFrom).format('YYYY-MM-DDTHH:mm:ss')
      : '';

    let dateToUTC = dates.dateTo
      ? dayjs(dates.dateTo).format('YYYY-MM-DDTHH:mm:ss')
      : '';

    if (dateFromUTC && dateToUTC)
      if (dayjs(dateFromUTC).isAfter(dateToUTC)) {
        toast.error('Incorrect date range!');
        return;
      }

    if (lastHoursActive) {
      dateToUTC = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      dateFromUTC = dayjs().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    }

    let dateFromValid = dateFromUTC === 'Invalid Date' ? null : dateFromUTC;
    let dateToValid = dateToUTC === 'Invalid Date' ? null : dateToUTC;

    getShortlistAll({
      article: globalFilter || null,
      dateFrom: dateFromValid || null,
      dateTo: dateToValid || null,
      page: page,
      size: 10,
      shortListNumber: 2,
      lastDayFilter: lastHoursActive || null,
    }).then((response) => {
      const data = response?.data;
      if (init) {
        setArticleFullData([...data]);
        localStorage.setItem('articles', JSON.stringify([...data]));
        localStorage.setItem('articles_length', data.length);
      } else {
        const newArray = [...articleFullData, ...data];
        setArticleFullData(newArray);
        localStorage.setItem('articles', JSON.stringify(newArray));
        localStorage.setItem('articles_length', newArray.length);
      }
      setArticles(response);
      setUpdateFetch(false);
    });
  };

  const handleLastHoursClick = () => {
    setLastHoursActive((prevState) => !prevState);
    setDates({
      dateFrom: '',
      dateTo: '',
    });
  };

  const handleStartDateChange = (newValue) => {
    if (newValue?.$y) setDates({ ...dates, dateFrom: newValue });
  };

  const handleEndDateChange = (newValue) => {
    if (newValue?.$y) setDates({ ...dates, dateTo: newValue });
  };

  const handleFilterReset = () => {
    setGlobalFilter('');
    setLastHoursActive(false);
    setDates({
      dateFrom: '',
      dateTo: '',
    });
    setUpdateFetch(true);
  };

  const handleRemoveAllShortlist = () => {
    removeAllShortlist(2).then(() => {
      setCurrentPage(0);
      setUpdateFetch(true);
    });
  };

  const handleRemoveOneShortlist = () => {
    const idsLocale = JSON.parse(localStorage.getItem('shortlist_ids'));
    const ids = idsLocale ? idsLocale : [];

    ids.forEach((id, index) => {
      removeOneShortlist(id).then(() => {
        localStorage.setItem('shortlist_ids', JSON.stringify([]));
        localStorage.setItem('pages', JSON.stringify([]));
        setCurrentPage(0);
        if (index === ids.length - 1) {
          setArticles(null);
          setUpdateFetch(true);
        }
      });
    });
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setCurrentLang('');
  };

  const handleOpenENReport = () => {
    setCurrentLang(reports?.Language[0]);
    setOpenExportModal(true);
  };

  const handleOpenARReport = () => {
    setCurrentLang(reports?.Language[1]);
    setOpenExportModal(true);
  };

  const handleDragEnd = (event) => {
    const orderPayload = {};
    const oldData = [...articleFullData];
    setArticles(null);

    const { draggableId, destination } = event;
    const destinationIndex = destination.index;

    let minCustomOrder = oldData[0].customOrder;

    oldData.forEach((item) => {
      if (item.customOrder < minCustomOrder) minCustomOrder = item.customOrder;
    });

    const currentIndex = oldData.findIndex((item) => item.id === +draggableId);
    oldData.move(currentIndex, destinationIndex);

    oldData.forEach((item, index) => {
      // console.log(minCustomOrder, index);
      orderPayload[item.id] = minCustomOrder + index;
    });

    const payload = {
      order: {
        ...orderPayload,
      },
    };

    reorderShortlist(payload).then(() => {
      setUpdateFetch(true);
    });
  };

  const handleAutoSort = () => {
    autoSortShortlist(2).then(() => setUpdateFetch(true));
  };

  const handleInfinityFetch = () => {
    const pageData = articles?.pageData;
    const MAX_LIMIT = pageData?.totalItems;
    if (articleFullData.length < MAX_LIMIT) {
      getArticleFetcher(pageData?.page + 1);
    }
  };

  useEffect(() => {
    getReportAll(2).then((response) => {
      setCurrentPage(0);
      setReports(response.data);
      localStorage.setItem('shortlist_ids', JSON.stringify([]));
    });
  }, []);

  const handleSearch = () => {
    getArticleFetcher(currentPage, true);
  };

  useEffect(() => {
    getArticleFetcher(currentPage, true);
  }, [lastHoursActive]);

  useEffect(() => {
    if (updateFetch) getArticleFetcher(currentPage, true);
  }, [updateFetch]);

  useEffect(() => {
    if (updateDashboard) getArticleFetcher(currentPage, true);
  }, [updateDashboard]);

  useEffect(() => {
    localStorage.setItem('shortlist_ids', JSON.stringify([]));
    localStorage.setItem('pages', JSON.stringify([]));
  }, []);

  if (articles === null) return <ScreenLoader />;

  return (
    <Paper overrideStyles={{ paddingBottom: '50px' }}>
      <Flexbox p="30px" gap="20px" flexDirection="column">
        <Flexbox alignItems="center" gap="20px">
          <H2 content="Shortlist" />
          <Box marginLeft="auto">
            <Paragraph
              content={'Total : ' + articles?.pageData?.totalItems}
              bold
              spacing
            />
          </Box>
        </Flexbox>
      </Flexbox>

      {articles !== null && (
        <DragDropTable
          onHistoryFetch={handleInfinityFetch}
          hasMore={articleFullData.length < articles?.pageData?.totalItems}
          data={articleFullData}
          columnShape={columnArticleShape}
          filtering
          onDragEnd={handleDragEnd}
          localeKey="shortlist_ids"
          actionProps={
            <Fragment>
              <Button label="Export in EN" onClick={handleOpenENReport} />
              <Button label="Export in AR" onClick={handleOpenARReport} />
              <Button
                label="Remove Selected"
                onClick={handleRemoveOneShortlist}
                overrideStyles={{
                  minWidth: '180px',
                  backgroundColor: '#F5222D',
                  '&:hover': {
                    backgroundColor: '#F5222D',
                  },
                }}
              />
            </Fragment>
          }
          removeActionProps={
            <Fragment>
              <Button onClick={handleAutoSort} label="Sort" />
              {articles?.data?.length !== 0 && (
                <Button
                  onClick={handleRemoveAllShortlist}
                  label="Remove All Articles"
                  overrideStyles={{
                    minWidth: '180px',
                    backgroundColor: '#F5222D',
                    '&:hover': {
                      backgroundColor: '#F5222D',
                    },
                  }}
                />
              )}
            </Fragment>
          }
          filterProps={
            <Fragment>
              <Flexbox flexDirection="column" gap="20px">
                <SearchField
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  placeholder="Search article..."
                  overrideStyles={{ width: '250px' }}
                />

                <Flexbox alignItems="center" gap="20px">
                  <OneDatePicker
                    placeholder="Date from"
                    value={dates.dateFrom}
                    onChange={handleStartDateChange}
                  />
                  <OneDatePicker
                    placeholder="Date end"
                    value={dates.dateTo}
                    onChange={handleEndDateChange}
                  />

                  <Button
                    onClick={handleLastHoursClick}
                    label="Last 24 h."
                    overrideStyles={{
                      minWidth: '120px',
                      backgroundColor: lastHoursActive
                        ? palette.primary
                        : palette.active,
                    }}
                  />

                  <Button
                    onClick={handleSearch}
                    label="Search"
                    overrideStyles={{
                      minWidth: '100px',
                    }}
                  />

                  {hasFilter && (
                    <ClearButton
                      title="Clear Filters"
                      onClick={handleFilterReset}
                    />
                  )}
                </Flexbox>
              </Flexbox>
            </Fragment>
          }
          currentPage={articles.pageData.page + 1 || 0}
          _update={updateFetch}
        />
      )}
      <ExportShortlistModal
        setUpdateAll={setUpdateFetch}
        open={openExportModal}
        onClose={handleCloseExportModal}
        data={reports?.ReportFormat || []}
        language={currentLang}
      />
    </Paper>
  );
};

export default ShortlistDatatable;
