/* eslint-disable react-hooks/exhaustive-deps */
import { useRowSelect, useTable } from 'react-table';
import React, { useEffect, useMemo, useState } from 'react';
import ScrollBar from 'simplebar-react';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';

import { SelectCheckbox } from 'components/Elements';
import { Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import {
  StyledBodyCell,
  StyledHeadCell,
  StyledPagination,
  StyledTableRow,
} from './styled';
import { getIDsFromObj } from 'features/dashboard/utlis/ultis';

const Datatable = ({
  columnShape,
  data = [],
  filtering,
  filterProps,
  actionProps,
  onPaginate,
  paginateCount,
  currentPage,
  _update,
  localeKey = 'ids',
  colorCell = true,
  removeActionProps,
}) => {
  const columns = useMemo(() => columnShape, []);
  const tableData = useMemo(() => data, [data]);

  const [selected, setSelected] = useState([]);
  const idsLocale = JSON.parse(localStorage.getItem(localeKey)) || [];

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const localePages = JSON.parse(localStorage.getItem('pages'));
              const pagesList = localePages ? [...localePages] : [];
              const isChecked = pagesList.includes(currentPage);

              // // console.log(isChecked);

              const handleSelect = (event) => {
                const value = event.target.checked;
                // setChecked(value);

                const localeArticles = JSON.parse(
                  localStorage.getItem('dashboard_articles'),
                );
                const localeIdList = JSON.parse(
                  localStorage.getItem(localeKey),
                );
                const currentDataIds = getIDsFromObj(localeArticles);

                const localeIdListLength = localeIdList.length;
                const currentDataIdsLength = currentDataIds.length;

                if (value) {
                  pagesList.push(currentPage);
                  localStorage.setItem('pages', JSON.stringify(pagesList));

                  if (currentDataIdsLength >= localeIdListLength) {
                    const uniqueArray = currentDataIds.filter(
                      (item) => localeIdList.indexOf(item) === -1,
                    );
                    const newArray = [...localeIdList, ...uniqueArray];
                    localStorage.setItem(localeKey, JSON.stringify(newArray));
                    setSelected(newArray);
                  } else {
                    const uniqueArray = currentDataIds.filter(
                      (item) => localeIdList.indexOf(item) === -1,
                    );
                    const newArray = [...localeIdList, ...uniqueArray];
                    localStorage.setItem(localeKey, JSON.stringify(newArray));
                    setSelected(newArray);
                  }
                } else {
                  const newPages = pagesList.filter(
                    (item) => item !== currentPage,
                  );
                  localStorage.setItem('pages', JSON.stringify(newPages));

                  if (currentDataIdsLength >= localeIdListLength) {
                    const uniqueArray = currentDataIds.filter(
                      (item) => !localeIdList.includes(item),
                    );
                    const newArray = [...uniqueArray];
                    localStorage.setItem(localeKey, JSON.stringify(newArray));
                    setSelected(newArray);
                  } else {
                    const uniqueArray = localeIdList.filter(
                      (item) => !currentDataIds.includes(item),
                    );
                    // console.log('uniqueArray', uniqueArray);
                    const newArray = [...uniqueArray];
                    localStorage.setItem(localeKey, JSON.stringify(newArray));
                    setSelected(newArray);
                  }
                }
              };

              return (
                <SelectCheckbox
                  {...getToggleAllRowsSelectedProps()}
                  onClick={handleSelect}
                  checked={isChecked}
                />
              );
            },
            Cell: ({ row }) => {
              const idsLocale = JSON.parse(localStorage.getItem(localeKey));
              const isChecked = idsLocale
                ? idsLocale.includes(row.original.id)
                : [];
              const currentID = row.original.id;

              const localePages = JSON.parse(localStorage.getItem('pages'));
              const pagesList = localePages ? [...localePages] : [];

              const handleSelect = () => {
                const list = idsLocale ? [...idsLocale] : [];
                if (!list.includes(currentID)) {
                  list.push(currentID);
                  localStorage.setItem(localeKey, JSON.stringify(list));
                  setSelected(list);
                } else {
                  const newPages = pagesList.filter(
                    (item) => item !== currentPage,
                  );
                  localStorage.setItem('pages', JSON.stringify(newPages));
                  const newList = list.filter((id) => id !== currentID);
                  localStorage.setItem(localeKey, JSON.stringify(newList));
                  setSelected(newList);
                }
              };

              return (
                <SelectCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={handleSelect}
                  checked={isChecked}
                />
              );
            },
          },
          ...columns,
        ]);
      },
    );

  const handleChangePagination = (_event, currentPageNo) => {
    onPaginate(currentPageNo - 1);
  };

  useEffect(() => {
    if (_update) setSelected([]);
  }, [_update]);

  const renderFilters = (
    <Flexbox p="0 30px 30px 30px" width="100%">
      <Flexbox width="100%" gap="20px" alignItems="flex-start">
        {filterProps}
      </Flexbox>

      <Flexbox
        flexDirection="column"
        width="100%"
        gap="20px"
        alignItems="end"
        marginLeft="auto"
      >
        {removeActionProps && (
          <Flexbox
            alignItems="center"
            justifyContent="end"
            width="100%"
            marginLeft="auto"
            gap="20px"
          >
            {removeActionProps}
          </Flexbox>
        )}

        {(selected.length > 0 || idsLocale.length > 0) && (
          <Flexbox
            alignItems="center"
            justifyContent="end"
            width="100%"
            marginLeft="auto"
            gap="20px"
          >
            <Paragraph content={`${idsLocale.length} Selected`} bold spacing />
            {actionProps}
          </Flexbox>
        )}
      </Flexbox>
    </Flexbox>
  );

  const renderTableHead = (
    <TableHead>
      {headerGroups.map((headerGroup, index) => (
        <StyledTableRow {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column, colIndex) => (
            <StyledHeadCell
              key={column.id + colIndex}
              sx={{
                minWidth: column.minWidth,
                textAlign: column.textAlign,
              }}
            >
              {column.render('Header')}
            </StyledHeadCell>
          ))}
        </StyledTableRow>
      ))}
    </TableHead>
  );

  const renderTableBody = (
    <TableBody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);

        return (
          <TableRow {...row.getRowProps()} key={index}>
            {row.cells.map((cell, cellIndex) => (
              <StyledBodyCell
                {...cell.getCellProps()}
                key={cellIndex}
                sx={{
                  backgroundColor: colorCell
                    ? !cell.row.original.isTranslated
                      ? 'unset'
                      : 'rgba(0, 0, 0, 0.1)'
                    : 'unset',
                }}
              >
                {cell.render('Cell')}
              </StyledBodyCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );

  return (
    <Box>
      {filtering && renderFilters}
      <ScrollBar>
        <Table {...getTableProps()}>
          {renderTableHead}
          {renderTableBody}
        </Table>
      </ScrollBar>

      <Stack alignItems="center" marginY="2rem">
        <StyledPagination
          shape="rounded"
          onChange={handleChangePagination}
          count={paginateCount}
          page={currentPage}
        />
      </Stack>
    </Box>
  );
};

export default Datatable;
