import Flexbox from 'components/Flexbox/Flexbox';
import React from 'react';
import { StyledButtonFormat, StyledDivTextarea } from './styled';

const TextEditor = ({
  onFormatText,
  name,
  onChange,
  value,
  unicodeBidi,
  formatName,
  formatedValue,
  onKeyDown,
}) => {
  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  if (name === 'mainTitle') console.log('format', formatedValue);

  return (
    <div>
      <Flexbox gap="10px">
        <StyledButtonFormat onClick={onFormatText(value, formatName, 36)}>
          H1
        </StyledButtonFormat>
        <StyledButtonFormat onClick={onFormatText(value, formatName, 32)}>
          H2
        </StyledButtonFormat>
        <StyledButtonFormat onClick={onFormatText(value, formatName, 26)}>
          H3
        </StyledButtonFormat>
        <StyledButtonFormat onClick={onFormatText(value, formatName, 24)}>
          H4
        </StyledButtonFormat>
        <StyledButtonFormat onClick={onFormatText(value, formatName, 20)}>
          H5
        </StyledButtonFormat>
        <StyledButtonFormat onClick={onFormatText(value, formatName, 18)}>
          H6
        </StyledButtonFormat>
        <StyledButtonFormat
          style={{ cursor: 'pointer' }}
          onClick={onFormatText(value, formatName, 14)}
        >
          P
        </StyledButtonFormat>
        <StyledButtonFormat
          style={{ cursor: 'pointer' }}
          onClick={onFormatText(value, formatName, 12)}
        >
          Small
        </StyledButtonFormat>
      </Flexbox>

      <StyledDivTextarea
        contentEditable
        onPaste={handlePaste}
        onKeyDown={onKeyDown}
        onBlur={onChange}
        id={name}
        style={{ unicodeBidi: unicodeBidi ? 'plaintext' : 'unset' }}
      >
        {formatedValue || value}
      </StyledDivTextarea>
    </div>
  );
};

export default TextEditor;
